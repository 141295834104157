<template>
  <div class="m-section-header">
    <div class="m-section-header__top">
      <AHeading
        v-if="title"
        :level="titleLevel"
      >
        {{ title }}
      </AHeading>
      <ALink
        v-if="linkHref && linkText"
        :href="getUrl(linkHref, !linkHref.includes('.html'))"
        underline
      >
        {{ linkText }}
      </ALink>

      <AButton
        v-if="withButton && locale === 'ru'"
        size="lg"
        class="i-city-red"
        variant="secondary"
        @click.stop="mainStore.openModalCity"
      >
        {{ buttonContent }}
      </AButton>
    </div>
    <p v-if="subtitle">
      {{ subtitle }}
    </p>
  </div>
</template>

<script lang="ts" setup>
import { useMainStore } from '@/stores'
import { useNavigate } from '@/composables/useNavigate'
import { useLocalization } from '#imports'

import type { PropType } from 'vue'
import type { HeaderLevel } from '@/components/ui-kit/a-heading/types'

import AHeading from '@/components/atoms/Heading/AHeading.vue'
import AButton from '@/components/atoms/Button/AButton.vue'
import ALink from '@/components/atoms/Link/ALink.vue'

defineProps({
  title: {
    type: String,
    default: ''
  },
  titleLevel: {
    type: [String, Number] as PropType<HeaderLevel>,
    default: 2
  },
  subtitle: {
    type: String,
    default: ''
  },
  linkHref: {
    type: String,
    default: ''
  },
  linkText: {
    type: String,
    default: ''
  },
  withButton: {
    type: Boolean,
    default: false
  },
  buttonContent: {
    type: String,
    default: ''
  }
})

const { getUrl } = useNavigate()
const { locale } = useLocalization()
const mainStore = useMainStore()
</script>

<style lang="postcss">
.m-section-header {
  & > div {
    display: flex;
    gap: var(--spacer-xs);
    align-items: flex-start;
    justify-content: space-between;

    & > .heading {
      @mixin text-5xl;
    }

    & > .link {
      margin-top: var(--spacer-3xs);
      margin-left: var(--spacer-xs);
      white-space: nowrap;
    }
  }

  & > p {
    margin-top: var(--spacer-4xs);
    color: var(--color-neutral-600);
  }

  @media (--screen-xs) {
    & > div {
      justify-content: space-between;

      & > .heading {
        @mixin text-2xl;
      }

      & > .link {
        margin-top: var(--spacer-4xs);

        &.underline {
          line-height: 1.25rem;
        }

        @mixin text-sm;
      }
    }

    & > p {
      font-size: var(--fs-sm);
    }
  }
}
</style>
