<template>
  <div
    v-if="seoTags.length || loading"
    class="o-seo-tags"
    :class="{ 'at-end': atEnd , 'at-start': atStart}"
  >
    <MSectionHeader
      :title="title"
      :subtitle="subtitle"
      title-level="2"
      :link-text="linkText"
      :link-href="linkHref"
    />
    <div
      ref="scrollRefContainer"
      @wheel.prevent="handleWheel"
      @scroll="handleScroll"
    >
      <div v-if="loading" class="o-seo-tags__skeleton">
        <div v-for="n in 8" :key="n" />
      </div>
      <template v-else-if="firstBlock.length">
        <div>
          <AChip
            v-for="item in firstBlock"
            :key="item.id"
            :path="item.link"
            @click.prevent="resetScrollAndPush(item.link, $event)"
          >
            {{ item.title }}
          </AChip>
        </div>
        <div v-if="secondBlock.length">
          <AChip
            v-for="item in secondBlock"
            :key="item.id"
            :path="item.link"
            @click.prevent="resetScrollAndPush(item.link, $event)"
          >
            {{ item.title }}
          </AChip>
        </div>
      </template>
    </div>
  </div>
</template>

<script lang="ts" setup>
import './SeoTags.css'
import { ref } from 'vue'
import { useCacheWrapper } from '#imports'
import { useNavigate } from '@/composables/useNavigate'

import type { InterestingSection } from '@/modules/nuxt-api/models/InterestingSection'

import AChip from '@/components/atoms/Chip/AChip.vue'
import MSectionHeader from '@/components/molecules/SectionHeader/MSectionHeader.vue'
import { useAPI } from '@/modules/nuxt-api/composables'

type Tag = {
  id: number
  title: string
  link: string
}

const props = defineProps({
  title: {
    type: String,
    default: ''
  },
  subtitle: {
    type: String,
    default: ''
  },
  linkText: {
    type: String,
    default: ''
  },
  linkHref: {
    type: String,
    default: ''
  },
  cacheHref: {
    type: String,
    default: ''
  },
  href: {
    type: String,
    default: ''
  },
  loading: Boolean
})

const { blankPush } = useNavigate()
const { getInterestingSection } = useAPI().catalog()

const scrollRefContainer = ref<any>()
const seoTags = ref<InterestingSection[]>([])
const firstBlock = ref<Tag[]>([])
const secondBlock = ref<Tag[]>([])
const atEnd = ref(false)
const atStart = ref(false)

try {
  seoTags.value = await useCacheWrapper(['seo-tags', props.href], async () => {
    const data = await getInterestingSection(props.href)

    return data || []
  })
} catch (er) {}

const calcTagsDataCount = (tags: Tag[]) => {
  if (tags.length > 4) {
    const endIdx = Math.ceil(tags.length / 2)
    firstBlock.value = tags.slice(0, endIdx)
    secondBlock.value = tags.slice(endIdx)
  } else {
    firstBlock.value = tags
    secondBlock.value = []
  }
}

function resetScrollAndPush (to: string, event: any) {
  window.scrollTo(0, 0)

  blankPush(to, event)
}

const handleWheel = (event: WheelEvent) => {
  const container = scrollRefContainer.value
  if (container) {
    const scrollAmount = Math.abs(event.deltaX) > Math.abs(event.deltaY) ? event.deltaX : event.deltaY
    container.scrollLeft += scrollAmount
  }
}

const handleScroll = (event: any) => {
  const target = event.target
  atEnd.value = (target.scrollWidth - Math.ceil(target.scrollLeft)) <= target.clientWidth
  atStart.value = target.scrollLeft >= 8
}

watch(seoTags, (value) => {
  const tagsData = value.map((el, i) => ({
    id: i,
    title: el.title,
    link: el.section?.path?.path ?? ''
  })) ?? []

  calcTagsDataCount(tagsData)
}, { immediate: true })
</script>
